import * as React from 'react';
import { StyledNavButton } from './styles';

interface INavButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
}

const NavButton: React.FunctionComponent<INavButtonProps> = ({ onClick, children }) => {
  return (
    <StyledNavButton onClick={onClick} type='button'>
      {children}
    </StyledNavButton>
  );
};

export default NavButton;
