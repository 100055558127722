import { ReactElement } from 'react';

interface BooleanReturningFunction {
  (): boolean;
}

interface RenderIfProps {
  children: ReactElement | ReactElement[];
  condition: boolean | BooleanReturningFunction;
}

const evaluate = (condition: boolean | BooleanReturningFunction) => {
  if (typeof condition === 'boolean') {
    return condition;
  }
  if (typeof condition === 'function') {
    return condition();
  }

  console.error('Invalid condition provided to RenderIf', { condition });
  return false;
};

const RenderIf = ({
  children,
  condition,
}: RenderIfProps): ReactElement | null => {
  if (evaluate(condition)) {
    return <>{children}</>;
  }

  return null;
};

export default RenderIf;
