import { applyOpacityToColor } from '@utils/index';
import styled from 'styled-components';

/*
 * This is a ready to use component, just import it and plop it into your project as:
 * <Navbar/>
 * You might want to move all the style components into separate files for readability
 * if you plan to do more with it.
 */

interface BarProps {
  backgroundColor?: string;
  border?: {
    color?: string;
    opacity?: string;
  };
  padding?: string;
}

export const Bar = styled.nav<BarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ padding }) => padding || '18px 16px 18px 32px'};
  background-color: ${({ backgroundColor }) => applyOpacityToColor(backgroundColor || '', 0.88) || `rgba(255, 250, 244, 0.88)`};
  box-shadow: ${({ border }) => border?.color ? `0px 1px 4px 0px ${border?.color}` : `0px 1px 4px 0px rgba(0, 0, 0, 0.08)`} ;
  backdrop-filter: blur(32px);
`;

export const WrapperContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
  /* Breakpoints */
  /* XL */
  /* @media screen and (min-width: 1512px) {
    max-width: 1512px;
  } */
`;

export const NavLi = styled.li`
  text-align: center;
  margin: 15px auto;
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
  gap: 12px;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CustomLogoWrapper = styled.div`
  width: 120px;
  height: 28px;
`;

export const CustomLogo = styled.img`
  width: auto;
  height: 28px;
`;
