import { CampaignMessage, CampaignStyle } from '@interfaces';
import { FC } from 'react';
import RenderIf from '@components/RenderIf';
import { Grid } from '@mui/material';
import callToActionClick from './callToActionClick';
import CallToActionContainer from './styles/CallToActionContainer';
import Bubble from './styles/Bubble';
import Image from './styles/Image';
import Text from './styles/Text';
import { getMessageColors } from './util';

interface MessageProps {
  isV2Theme?: boolean;
  styles: CampaignStyle;
  message?: CampaignMessage | CampaignMessage[];
  trackCTAClick?: () => Promise<void> | void;
  isMultipleCTAsSupported?: boolean;
}

const Message: FC<MessageProps> = ({
  isMultipleCTAsSupported,
  isV2Theme = false,
  message,
  styles,
  trackCTAClick,
}) => {
  const { CTAStyle } = styles;
  const messages: CampaignMessage[] | undefined = (isMultipleCTAsSupported
    ? message
    : [message]) as CampaignMessage[];

  const handleBubbleClick = (isButton: boolean, msg: CampaignMessage) => {
    if (isButton) {
      callToActionClick(msg, trackCTAClick);
    }
  };

  return (
    <Grid container spacing={2}>
      <RenderIf condition={!!messages}>
        <>
          {messages &&
            messages?.map((msg, index) => {
              const side = msg?.side || 'left';
              const kind = msg?.kind;
              const view = msg?.view;
              const text = msg?.text;
              const asset = msg?.asset;
              const isAsset = kind === 'asset';
              const isButton = kind === 'button' || kind === 'link';
              const messagesColors = getMessageColors(styles, msg);
              const textColor = messagesColors?.text;
              const backgroundColor = messagesColors?.background;
              
            if (text === '\n' || text?.length <= 0 || asset?.url === '' || text == undefined) return
            
            return (
              <Grid item xs={12} md={messages.length <= 3 ? 12 / messages.length : 4} key={index}>
                <CallToActionContainer side={side} kind={kind} key={index}>
                  <Bubble
                    isV2Theme={isV2Theme}
                    kind={kind}
                    side={side}
                    view={view}
                    backgroundColor={backgroundColor}
                    CTAStyle={CTAStyle}
                    onClick={() => handleBubbleClick(isButton, msg)}
                  >
                    <RenderIf condition={isAsset}>
                      <Image src={asset?.url} alt="asset" />
                    </RenderIf>
                    <RenderIf condition={!isAsset}>
                      <Text
                        isV2Theme={isV2Theme}
                        kind={kind}
                        color={textColor || '#FFFFFF'}
                        additionalStyles={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {text || '•••'}
                      </Text>
                    </RenderIf>
                  </Bubble>
                </CallToActionContainer>
              </Grid>
            )
          })}
        </>
      </RenderIf>
    </Grid>
  );
};

export default Message;
