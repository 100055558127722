import { createGlobalStyle } from 'styled-components';

interface Props {
  background?: string;
}

export default createGlobalStyle<Props>`
  * {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
  }

  *::-webkit-scrollbar {
    display: none;
  }

  html {
    font-size: 10px;
    font-family: 'Inter UI', sans-serif;
    background-color: ${({ background }) => background || '#ffffff'};
  }

  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter UI', sans-serif; }
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }


  :root {
    --ud-color-sh1: #6140FF;
    --ud-color-sh2: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #6140FF;
    --ud-color-sh3: linear-gradient(0deg, #5136D6, #5136D6), #6140FF;
    --uo-color-sh1: transparent;
    --uo-color-sh2: rgba(97, 64, 255, 0.02);
    --uo-color-sh3: rgba(97, 64, 255, 0.06);
    --uo-color-sh4: rgba(97, 64, 255, 0.08);
    --color-dark-base: #000000;
    --color-light-base: #FFFFFF;
    --color-red-base: #FB634F;

    --color-base: #FEFAF5;
    --grey-medium: #6E697E;
    --grey-dark: #DDDDDD;
    --border-radius: 4px;
    --global-background: ${({ background }) => background || '#fff'};
    }
`;
