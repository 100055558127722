import { CampaignMessageKind } from '@interfaces';
import styled, { css, CSSObject } from 'styled-components';

interface TextProps {
  color: string;
  kind?: CampaignMessageKind;
  additionalStyles?: CSSObject;
  isV2Theme?: boolean;
}

const Text = styled.p<TextProps>`
  color: ${({ color }) => color};
  font-weight: 400;
  margin: 0px;

  font-family: 'Inter UI', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  
  /* V2 */
  ${({ isV2Theme }) => isV2Theme && css`
  font-family: Lexend-Medium;
  font-style: normal;
  line-height: 24px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  `}
  

  ${({ kind }) =>
    (kind === 'button' || kind === 'link') &&
    css`
      font-weight: 700;
      text-align: center;
    `}

  ${({ additionalStyles }) => additionalStyles && css(additionalStyles)}
`;

export default Text;
