/* eslint-disable */

interface ScriptPropetiesProps {
  tagName: string;
  content: string;
  src: string;
  id: string;
  isAsync: boolean;
}

export const useElementProperties = (): (html: string) => ScriptPropetiesProps => {
  const getElementProperties = (html: string): ScriptPropetiesProps => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const element = doc.body.firstChild || doc.head.firstChild;

    if (!element) {
      return { tagName: "", content: "", src: "", id: "", isAsync: false }
    }

    return {
      tagName: element.nodeName.toLowerCase(),
      content: element.textContent || "",
      src: element instanceof HTMLElement ? element.getAttribute('src') || "" : "",
      id: element instanceof HTMLElement ? element.getAttribute('id') || "" : "",
      isAsync: element instanceof HTMLElement ? !!element.getAttribute('async') : false,
    };
  }

  return getElementProperties;
}