import * as React from 'react';

import { useCreateCalendar } from '@hooks/lib/useCreateCalendar';
import { CampaignCalendar } from '@interfaces';
import { CalendarWrapper } from './styles';

export interface ICalendarProps {
  calendar?: CampaignCalendar;
}

const Calendar: React.FunctionComponent<ICalendarProps> = ({
  calendar = {},
}) => {
  const idCalendarContainer = 'calendar-container';
  
  const { createCalendar } = useCreateCalendar({
    containerId: idCalendarContainer,
    calendar,
  });

  React.useEffect(() => {
    if (!calendar) {
      return;
    }
    createCalendar();
  }, [
    calendar.link,
    calendar.provider,
  ]);

  return <CalendarWrapper id={idCalendarContainer} />;
};

export default Calendar;
