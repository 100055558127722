import React, { ReactNode } from 'react';
import { StyledFooter, StyledLayout, StyledLeft, StyledMain, StyledMainLemCal, StyledRight, StyledRightLemCal, StyledTop } from './styles';
import { CALENDAR_CUSTOM_LAYOUTS, CALENDAR_PROVIDERS } from '@constants/constants';

interface LayoutProps {
  children: ReactNode;
  backgroundImage?: string;
  backgroundGradient?: string;
}

interface LayoutComponent extends React.FC<LayoutProps> {
  Top: typeof Top;
  Main: typeof Main;
  Left: typeof Left;
  Right: typeof Right;
  Footer: typeof Footer;
  [key: string]: any;
}

const Layout: LayoutComponent = ({ children, backgroundImage, backgroundGradient }: LayoutProps) => {
  return (
    <StyledLayout
      backgroundImage={backgroundImage}
      backgroundGradient={backgroundGradient}
    >
      {children}
    </StyledLayout>
  );
};

const Top = ({ children }: { children: ReactNode }) => {
  return <StyledTop>{children}</StyledTop>;
};

const Main = ({ children }: { children: ReactNode }) => {
  return <StyledMain>{children}</StyledMain>;
};

const Left = ({ children }: { children: ReactNode }) => {
  return <StyledLeft>{children}</StyledLeft>;
};

const Right = ({ children }: { children: ReactNode }) => {
  return <StyledRight>{children}</StyledRight>;
};

const Footer = ({ children }: { children: ReactNode }) => {
  return <StyledFooter>{children}</StyledFooter>;
};

const MainLemCal = ({ children }: { children: ReactNode }) => {
  return <StyledMainLemCal>{children}</StyledMainLemCal>;
};
const RightLemCal = ({ children }: { children: ReactNode }) => {
  return <StyledRightLemCal>{children}</StyledRightLemCal>;
};

Layout.Top = Top;
Layout.Main = Main;
Layout.Left = Left;
Layout.Right = Right;
Layout.Footer = Footer;

// LEMCAL custom layout
Layout[CALENDAR_CUSTOM_LAYOUTS[CALENDAR_PROVIDERS.LEMCAL].Right] = RightLemCal;
Layout[CALENDAR_CUSTOM_LAYOUTS[CALENDAR_PROVIDERS.LEMCAL].Main] = MainLemCal;

export default Layout;