import styled from 'styled-components';

export const StyledNavButton = styled.button`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  display: flex;
  padding: 6px;
  align-items: flex-start;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 50%;

  @media (hover: hover) {
    &:hover {
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.08);
    }
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.12);
  }
`;
