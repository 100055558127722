/* eslint-disable no-template-curly-in-string */
import { CampaignCalendar } from '@interfaces';
import { useElementProperties } from './useElementProperties';
import { CALENDAR_PROVIDERS } from '@constants/constants';

interface UseCreateCalendarProps {
  containerId: string;
  calendar: CampaignCalendar;
}

export const useCreateCalendar = ({
  containerId,
  calendar,
}: UseCreateCalendarProps) => {
  const getElementProperties = useElementProperties();
  const { link, codeSnippets, provider } = calendar;

  const createCalendar = () => {
    codeSnippets?.forEach((codeSnippet) => {
      let newSnippet = codeSnippet?.snippet?.replace('${link}', link || '');

      if (!newSnippet) {
        return null;
      }

      try {
        if (provider === CALENDAR_PROVIDERS.LEMCAL) {
          const linkPath = link ? new URL(link).pathname : '';
          const [_, userId, meetingType] = linkPath.split('/');
          newSnippet = newSnippet
            .replace('${userId}', userId || '')
            .replace('${meetingType}', meetingType || '');
        }
      } catch (error) {
        console.log('Error processing calendar snippet:', error);
      }

      const snippet = getElementProperties(newSnippet);

      if (codeSnippet.position === 'head') {
        const scriptElements = document.querySelectorAll('.custom-calendars');
        scriptElements.forEach((element) => {
          element.remove();
        });
        const container = document.createElement('script');
        container.id = snippet.id;
        container.className = 'custom-calendars';
        container.src = snippet.src;
        container.async = true;
        container.innerHTML = snippet.content;

        document.head.appendChild(container);
      } else if (codeSnippet.position === containerId) {
        const container = document.getElementById(containerId);
        if (!container) {
          return null;
        }
        // Clean the container content
        container.innerHTML = '';

        if (snippet.tagName === 'div') {
          const ele = document.createElement('div');
          ele.id = snippet.id;
          ele.classList.add(provider || '');
          ele.innerHTML = newSnippet || '';
          ele.style.minWidth = '352px';
          ele.style.width = '100%';
          ele.style.height = '100%';
          ele.style.overflowX = 'hidden';
          ele.style.overflowY = 'scroll';
          ele.style.borderRadius = '8px';

          container.appendChild(ele);
        }

        if (snippet.tagName === 'iframe') {
          if (!snippet.id) {
            snippet.id = 'calendar-iframe';
          }
          let iframe = document.getElementById(snippet.id) as HTMLIFrameElement;
          if (!iframe) {
            iframe = document.createElement('iframe');
            iframe.id = snippet.id;
            container.appendChild(iframe);
          }
          iframe.classList.add(provider || '');
          iframe.src = snippet.src;
          iframe.title = snippet.id;
          iframe.style.minWidth = '100%';
          iframe.style.minHeight = '100%';
          iframe.style.border = 'none';
          iframe.setAttribute('sandbox', 'allow-scripts allow-same-origin');

          iframe.addEventListener('load', () => {
            container.style.backgroundColor = 'transparent';
          });
        }
      }
    });
  };

  return { createCalendar };
};
