import { CampaignMessage } from '@interfaces';

type CallToActionClick = (
  message?: CampaignMessage | undefined,
  trackCTAClick?: (() => void) | undefined
) => void;

const callToActionClick: CallToActionClick = (message, trackCTAClick) => {
  const link = message?.link || process.env.NEXT_PUBLIC_SENDSPARK_SITE;
  if (trackCTAClick) trackCTAClick();

  window.open(link, '_blank');
};

export default callToActionClick;
