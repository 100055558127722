
import NavButton from '@components/NavButton';
// import { SvgPencil } from '@assets/SvgPencil';
// import { SvgDownloadArrow } from '@assets/SvgDownloadArrow';
import { SvgLink } from '@assets/SvgLink';
import { SvgSendsparkLogo } from '@assets/SvgSendsparkLogo';

import { SvgDownloadArrow } from '@assets/SvgDownloadArrow';
import { SVGFacebook } from '@assets/SVGFacebook';
import RenderIf from '@components/RenderIf';
import { Bar, CustomLogo, CustomLogoWrapper, MenuWrapper, WrapperContent } from './styles';
import { NavbarThemeStyle } from '@interfaces';

interface NavBarProps {
  isOwnerVideo?: boolean;
  handleOnEdit?: () => void;
  handleOnDownload?: () => void;
  handleOnCopyLink?: () => void;
  url?: string;
  size?: number;
  background?: string;
  border?: {
    color?: string;
  };
  icon?: {
    color?: string;
  };
  isNCSU?: boolean;
  navbarThemeStyle?: NavbarThemeStyle;
}

const NavBar = ({
  // handleOnEdit,
  handleOnDownload,
  handleOnCopyLink,
  // isOwnerVideo = false,
  url,
  background,
  border,
  icon,
  isNCSU = false,
  navbarThemeStyle
}: NavBarProps) => {
  const handleFacebookShare = () => {
    window.open(`https://www.facebook.com/share.php?u=${window.location.href}`, "_blank");
  }
  const iconColor = navbarThemeStyle?.iconColor || icon?.color;

  return (
    <Bar
      backgroundColor={navbarThemeStyle?.background || background}
      border={border}
      padding={navbarThemeStyle?.padding}
    >
      <WrapperContent>
        <>
          <RenderIf condition={!!url}>
            <CustomLogoWrapper>
              <CustomLogo src={url} />
            </CustomLogoWrapper>
          </RenderIf>
          <RenderIf condition={!url}>
            <SvgSendsparkLogo />
          </RenderIf>
        </>
        <MenuWrapper>
          {/* <RenderIf condition={isOwnerVideo}>
          <NavButton onClick={handleOnEdit}> <SvgPencil /></NavButton>
        </RenderIf> */}
          <RenderIf condition={isNCSU}>
            <NavButton onClick={handleFacebookShare}>
              <SVGFacebook color={iconColor} />
            </NavButton>
            <NavButton onClick={handleOnDownload}>
              <SvgDownloadArrow color={iconColor} />
            </NavButton>
          </RenderIf>
          <NavButton onClick={handleOnCopyLink}><SvgLink color={iconColor} /></NavButton>
        </MenuWrapper>
      </WrapperContent>
    </Bar>
  )
}

export default NavBar;