import React from 'react';

export interface SvgLinkProps {
  size?: string;
  color?: string;
  stroke?: string;
}

export const SvgLink: React.FC<SvgLinkProps> = ({
  size = '24',
  color = "black",
  stroke = "2"
}) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H9M15 17H17C19.7614 17 22 14.7614 22 12C22 9.23858 19.7614 7 17 7H15M7 12L17 12"
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>
);
