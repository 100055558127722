import React, { useState } from 'react';

import { DescriptionContainer, ShowMoreText } from './styles';


interface ShowMoreTextContainerProps {
  color: string;
  text: string;
  maxLength: number;
  showMoreColor?: string;
}

const ShowMoreTextContainer: React.FC<ShowMoreTextContainerProps> = ({ color, text, maxLength, showMoreColor }) => {
  const [showFullText, setShowFullText] = useState(false);

  if (text.length <= maxLength) {
    return <DescriptionContainer color={color}>{text}</DescriptionContainer>;
  }

  return (
    <DescriptionContainer color={color}>
        {showFullText ? text : (
        <>
          {text.substring(0, maxLength - 7)}
          <span className="fade-out-text">{text.substring(maxLength - 7, maxLength)}</span>
        </>
      )}
      <ShowMoreText onClick={() => setShowFullText(!showFullText)} color={showMoreColor}>
        {showFullText ? <span className='less'>Show Less</span> : <span className='more'>...Show More</span>}
      </ShowMoreText>
    </DescriptionContainer>
  );
};

export default ShowMoreTextContainer;