import { CampaignStyle, CampaignMessage } from '@interfaces/index';

interface GetMessagesColorsResponse {
  text: string | undefined;
  background: string | undefined;
}

type GetMessagesColors = (
  style: CampaignStyle,
  messsage?: CampaignMessage
) => GetMessagesColorsResponse | undefined;

export const getMessageColors: GetMessagesColors = (style, messsage) => {
  if (!messsage) {
    return;
  }

  const { kind, side } = messsage;

  if (kind === 'text' && side !== 'right') {
    return {
      text: style.messageLeftText,
      background: style.messageLeftBackground,
    };
  }

  return {
    text: style.responseButtonText,
    background: style.responseButtonBackground,
  };
};
