import styled, { keyframes,css } from 'styled-components';

interface StyledLayoutProps {
  backgroundImage?: string;
  backgroundGradient?: string;
}

interface StyledContentProps {
  animated?: boolean;
}

// Animations
const fadeIn = keyframes`
  0% {
   opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// Components
export const StyledLayout = styled.div<StyledLayoutProps>`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: ${({ backgroundImage, backgroundGradient }) => {
    const image = backgroundImage
      ? `url(${backgroundImage}) no-repeat top center`
      : '';

    return [backgroundGradient, image].filter(Boolean).join(', ');
  }};
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  padding-bottom: 36px;
`;

export const StyledTop = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Breakpoints 03 */
  @media screen and (max-width: 1199px), screen and (max-height: 900px) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
  }
`;

export const StyledMain = styled.main`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: center;
  padding-top: 32px;
  width: 100%;
  max-width: 1408px;
  overflow: hidden;

  /* XL */
  @media screen and (min-width: 1512px) {
    max-width: 1408px;

    > aside:only-child {
      max-width: 928px;
    }
  }
  /* LG */
  @media screen and (min-width: 1200px) and (max-width: 1511px) {
    max-width: 1120px;

    > aside:only-child {
      max-width: 928px;
    }
  }

  /* Breakpoints 03 */
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    max-width: 688px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 0;
  }

  /* Breakpoints 04 */
  @media screen and (min-width: 500px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 24px;
  }

  /* Breakpoints 05 */
  @media screen and (max-width: 499px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
  }
`;

export const StyledMainLemCal = styled.main`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: center;
  padding-top: 32px;
  width: 100%;
  max-width: 1408px;

    /* XL */
    @media screen and (min-width: 1611px) {
      max-width: 1408px;
    
      > aside:only-child {
        max-width: 928px;
      }
    }
    /* LG */
    @media screen and (min-width: 1200px) and (max-width: 1610px) {
      max-width: 688px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 32px 0;
    }

    /* Breakpoints 03 */
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      max-width: 688px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 32px 0;
    }

    /* Breakpoints 04 */
    @media screen and (min-width: 500px) and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 32px 24px;
    }

    /* Breakpoints 05 */
    @media screen and (max-width: 499px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 24px;
    }
`;

export const StyledLeft = styled.aside`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  /* XL */
  @media screen and (min-width: 1512px) {
    min-width: 928px;
    max-width: 928px;
  }
  /* LG */
  @media screen and (min-width: 1200px) and (max-width: 1511px) {
    /* min-width: 736px; */
    max-width: 736px;
  }

  /* Breakpoints 03 */
  @media screen and (min-width: 768px) and (max-width: 1199px) {
  }

  /* Breakpoints 04 */
  @media screen and (min-width: 500px) and (max-width: 767px) {
  }

  /* Breakpoints 05 */
  @media screen and (max-width: 499px) {
  }
`;

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  &:has(div:nth-child(2)) {
    gap: 20px;
  }

  @media (max-width: 1512px) {
    /* width: 736px; */
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 768px) {
    /* width: 452px; */
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  @media (max-width: 430px) {
    width: 100%;
  }
`;

export const StyledRight = styled.aside`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;

  /* XL */
  @media screen and (min-width: 1512px) {
    max-width: 448px;
    min-width: 448px;
  }
  /* LG */
  @media screen and (min-width: 1200px) and (max-width: 1511px) {
    min-width: 352px;
    max-width: 352px;
  }

  /* Breakpoints 03 */
  @media screen and (min-width: 768px) and (max-width: 1199px) {
  }

  /* Breakpoints 04 */
  @media screen and (min-width: 500px) and (max-width: 767px) {
  }

  /* Breakpoints 05 */
  @media screen and (max-width: 499px) {
  }
`;

export const StyledRightLemCal = styled.aside`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  max-width: 652px;
`;

export const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 36px;
`;

// DELETE THESE LINEs
export const StyledVideoPlayer = styled.div`
  width: 100%;
  height: auto;
  border-radius: 8px;
  overflow: hidden;

  & #sendspark-video-container,
  video {
    max-height: 523px !important;
    min-width: 100% !important;
    border-radius: 8px;
    overflow: hidden;
  }
  & video {
    border-radius: 8px;
    max-height: none !important;
  }

  /* Breakpoints 05 */
  @media screen and (max-width: 499px) {
    & video {
      border-radius: 0;
    }
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  color: ${({ color }) => color || '#000000'};
  font-family: Lexend-Semibold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 160% */
  letter-spacing: -0.01em;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StyledAuthor = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;

export const AuthorDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
export const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const Name = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  color: #000;
  text-align: center;
  font-family: Lexend-Bold;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 133.333% */
`;

export const MetaData = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  color: var(--Secondary-Text, rgba(0, 0, 0, 0.64));
  text-align: center;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  opacity: 0.64;
`;

export const MetaLink = styled.a`
  cursor: pointer;
  color: #6140ff;
  text-align: center;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const SocialNetworks = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  opacity: 0.24;
`;

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  padding: 0;
`;

export const StyledContent = styled.div<StyledContentProps>`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  ${({ animated }) =>
    animated &&
    css`
      animation: ${fadeIn} .5s linear 5s forwards;
      opacity: 0;
    `}
`;


