import styled from "styled-components";

import { applyOpacityToColor } from "@utils/index";

export const DescriptionContainer = styled.div`
  /* display: flex; */
  /* flex-direction: column;
  justify-content: center; */
  /* align-items: flex-start; */
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  width: 100%;
  overflow: hidden;
  color: ${({ color }) => applyOpacityToColor(color || '', 0.64) || ` var(--Secondary-Text, rgba(0, 0, 0, 0.64))`};
  text-overflow: ellipsis;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  position: relative;

  & span.fade-out-text {
    -webkit-mask-image: linear-gradient(to right, white, transparent);
    mask-image: linear-gradient(to right, white, transparent);
  }

  & span.less{
    display: block;
  }
  & span.more {
    margin: 0 4px;  
  }

  & span.blur{
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100px;
      height: 100%;
      background: linear-gradient(to right, transparent, var(--global-background) 75%);
      filter: blur(2px);
    }
  }
  `;

interface ShowMoreTextProps {
  color?: string;
}

export const ShowMoreText = styled.span<ShowMoreTextProps>`
  color: ${({ color }) => color || '#6140FF'};
  cursor: pointer;
`;
