import styled from 'styled-components';

export const CalendarWrapper = styled.div`
  height: 730px;
  width: 100%;
  min-height: 100%;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #ebebeb;
  border: 1px solid #ebebeb;

  &:has(.hubspot-embed-calendar) {
    border: none;
    background-color: transparent;
    height: 760px;
  }
  &:has(.calendly-embed-calendar) {
    height: 700px;
  }

  &:has(.tidycal-embed-calendar) {
    height: 700px;
  }

  &:has(> div iframe) {
    background-color: transparent;
  }
`;
